import { createAction, createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "apis/store/StoreAction";

const initialState = {
  user: "",
  token: "",
};

export const logout = createAction("auth/logout");


export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      state.user = { ...payload };
      state.token = { ...payload };
    },
    toggleOnBoarding: (state, { payload }) => {
      state.isBoarded = payload !== undefined ? !!payload : !state.isBoarded;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(logoutAction, () => ({ ...initialState }))
  },
});

export const { setCredentials } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
