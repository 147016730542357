import React from "react";
import { FormatNumber } from "utils/Utils";

export const DashboardCard = ({ icon, title, count }) => {
  return (
    <div className="bg-gradient-to-b from-[#4C50A9] via-[#4C50A9]/20 to-[#4C50A9] flex flex-col justify-between w-[200px] h-[120px] rounded-xl p-4 m-2 text-white">
      <p>{icon}</p>
      <p className="font-bold text-xl">{FormatNumber(count)}</p>
      <p className="text-sm">{title}</p>
    </div>
  );
};
