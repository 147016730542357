import React from "react";

const CustomInput = (props) => {
  const { label, type = "text" } = props;
  // console.log(props)
  return (
    <div className="w-full">
      {/* {console.log(props)} */}
      <label
        htmlFor={label}
        className={!!label ? "text-xs mb-2 text-gray-500" : "hidden"}
      >
        {label}
      </label>
      <div className="relative">
        {/* Trigger loader */}
        {props.loader ? (
          <div className="flex self-center justify-center items-center absolute h-[2.8rem] w-full bg-white/20 rounded-xl">
            {props.loader}
          </div>
        ) : null}
        <input
          type={type}
          className={`bg-gray-500/60 text-white border outline-none rounded-full w-full p-2  border-black/30 ${
            props?.helpertext
              ? "border-red-500 disabled:outline-white/10 focus:border-2 focus:border-red-500 hover:border-red-500"
              : "border-black/30 disabled:text-gray-400 disabled:bg-white/10 disabled:outline-white/10 disabled:focus:border-0 disabled:hover:border-0 focus:border-2 focus:border-[#4C50A9] hover:border-[#4C50A9]"
          }`}
          {...props}
        />
        <span className="absolute my-auto right-5 top-[30%] cursor-pointer">
          {props.suffix}
        </span>
      </div>
      {props?.helpertext ? (
        <>
          <span className="ml-2 text-xs text-red-600">{props?.helpertext}</span>
        </>
      ) : null}
    </div>
  );
};

export const CustomInputOutline = (props) => {
  const { label, type = "text" } = props;
  // console.log(props)
  return (
    <div className="w-full">
      <label htmlFor={label} className={!!label ? "text-xs mb-2" : "hidden"}>
        {label}
      </label>
      <div className="relative">
        <span
          className={
            props.prefix ? "absolute top-[20%] bottom-0 left-1" : "hidden"
          }
        >
          {props.prefix}
        </span>
        {/* Trigger loader */}
        {props.loader ? (
          <div className="flex self-center justify-center items-center absolute h-[2.8rem] w-full bg-white/20 rounded-xl">
            {props.loader}
          </div>
        ) : null}
        <input
          type={type}
          className={`${
            props.prefix ? "pl-8" : ""
          } relative bg-transparent text-white outline-[${
            props.outlinecolor ? props.outlinecolor : "#4C50A9"
          }] outline-1 outline rounded-full w-full p-2 ${
            props?.helpertext
              ? "outline-red-700 disabled:outline-white/30 focus:outline-2 focus:outline-red-700 hover:outline-red-700"
              : "border-black/30 disabled:outline-white/30 disabled:focus:outline-black/30 focus:border-2 focus:outline-[#4C50A9] hover:outline-[#4C50A9]"
          }`}
          {...props}
        />
        <span
          className={
            props.passwordvisibility
              ? "absolute my-auto right-5 top-[30%] cursor-pointer"
              : "hidden"
          }
        >
          {props.passwordvisibility}
        </span>
        <span
          className={
            props.suffix
              ? "absolute my-auto right-1 top-[5%] cursor-pointer"
              : "hidden"
          }
        >
          {props.suffix}
        </span>
      </div>
      {props?.helpertext ? (
        <>
          <span className="ml-2 text-xs text-red-700">{props?.helpertext}</span>
        </>
      ) : null}
    </div>
  );
};

export const SearchInputOutline = (props) => {
  const { label, type = "text" } = props;
  // console.log(props)
  return (
    <div className="w-full">
      <label htmlFor={label} className={!!label ? "text-xs mb-2" : "hidden"}>
        {label}
      </label>
      <div className="relative">
        <span
          className={
            props.prefix ? "absolute top-[20%] bottom-0 left-1" : "hidden"
          }
        >
          {props.prefix}
        </span>
        {/* Trigger loader */}
        {props.loader ? (
          <div className="flex self-center justify-center items-center absolute h-[2.8rem] w-full bg-white/20 rounded-xl">
            {props.loader}
          </div>
        ) : null}

        <input
          type={type}
          className={`${
            props.prefix ? "pl-8" : ""
          } relative bg-transparent text-white outline-[#888787] shadow-inner outline-[1.5] outline rounded-xl w-full p-2 ${
            props?.helpertext
              ? "outline-red-700 disabled:outline-white/30 focus:outline-2 focus:outline-red-700 hover:outline-red-700"
              : "border-black/30 disabled:outline-white/30 disabled:focus:outline-black/30 focus:border-2 focus:outline-[#4C50A9] hover:outline-[#4C50A9]"
          }`}
          {...props}
        />
        <span
          className={
            props.passwordvisibility
              ? "absolute my-auto right-5 top-[30%] cursor-pointer"
              : "hidden"
          }
        >
          {props.passwordvisibility}
        </span>
        <span
          className={
            props.suffix
              ? "absolute my-auto right-1 top-[5%] cursor-pointer"
              : "hidden"
          }
        >
          {props.suffix}
        </span>
      </div>
      {props?.helpertext ? (
        <>
          <span className="ml-2 text-xs text-red-700">{props?.helpertext}</span>
        </>
      ) : null}
    </div>
  );
};

export default CustomInput;
