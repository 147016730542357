

export const COLORS = {
  // purple: "#5b65f0",
  // primary: "#535FCC",
  // primary: "#1260CC",
  primary: "#4C50A9",
  background:"#000137",
  blue: "#013356",
  red: "#D80000",
  white: "#ffffff",
  black: "#000000",
  gray: "##C4C4C4",
  lightgray: "#f2f2f2",
  lightgray2: "#F2F2F2",
  darkGray: "#768089",
  orange: "#F18142",
  secondary: "#38160F90",
  darkOverlayColor: "rgba(0, 0, 0, 0.4)",
  darkOverlayColor2: "rgba(0, 0, 0, 0.8)",
  lightOverlayColor: "rgba(255, 255, 255, 0.10)",
  primaryAlpha: "rgba(99, 122, 255, 0.10)",
  redAlpha: "rgba(255, 84, 84, 0.30)",
  greenAlpha: "rgba(96, 197, 168, 0.30)",
  greenAlphaLight: "rgba(240, 253, 244, 1)",
  purpleAlpha: "rgba(146, 6, 228, 0.30)",
};

// export const primaryColor = "#253DA1";
export const primaryColor = "#4C50A9";
export const blueColor = "#013356";
export const blueColor2 = "#0C466F";
export const redColor = "#D80000";
export const grayColor = "##C4C4C4";
export const lightgrayColor = "#f2f2f2";
export const darkgrayColor = "#768089";
export const orangeColor = "#F18142";
export const secondaryColor = "#38160F90";

export const successfulColor = "#9FDB82";
export const pendingColor = "#FFFB90";
export const failedColor = "#F96D6D";
export const systemColor = "#9747FF";
export const bankColor = "#2C8DFF";
export const customerColor = "#3C6183";
export const naturalColor = "#3B4044";
