import React from "react";
import Lottie from "lottie-react";
import NoData from "../assets/lottie/NoData.json";

export const NoDataFound = ({ width, height }) => {
  return (
    <div className="relative backdrop-opacity-10 bg-white/5 h-full w-full flex justify-center items-center">
      <Lottie animationData={NoData} style={{ width: width, height: height,}} />
    </div>
  );
};