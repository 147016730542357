import "@fontsource/poppins";
import "@fontsource-variable/baloo-2";
import "./App.css";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "./utils/Theme";

import DetectNetworkConnectivity from "./components/DetectNetworkConnectivity";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRouter from "AppRouter";
// import { ContactUs } from "components/ContactForm";

// Lazy loading components
const Layout = React.lazy(() => import("./pages/layout/Layout"));

const contextClass = {
  success: "bg-green-500/50",
  error: "bg-red-600/50",
  info: "bg-gray-600/50",
  warning: "bg-orange-400/50",
  default: "bg-blue-800/50",
  dark: "bg-white-600 font-gray-300"
};

function App() {
  return (
    <>
      <ToastContainer
        toastClassName={({ type }) =>
          contextClass[type || "default"] +
          " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
        }
        bodyClassName={() => "flex text-sm font-white font-bold p-3"}
        position="top-center"
        autoClose={3000}
      />
      <DetectNetworkConnectivity />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRouter />
      </ThemeProvider>
    </>
  );
}

export default App;
