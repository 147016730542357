import React from "react";

const styles = {
  h1: { fontSize: "20px", fontWeight: "bold", color: "white" },
  h2: { fontSize: "16px", fontWeight: "bold", color: "white" },
  h3: { fontSize: "14px", fontWeight: "bold", color: "white" },
  color: "#c0c0c0"
};

export const RefundPolicy = () => {
  return (
    <div style={styles} className="p-10 text-justify space-y-3">
      <h1 style={styles.h1}>Refund Policy</h1>
      <p>Last updated: June 08, 2024</p>
      <p>Thank you for shopping at LOGSPLUG.</p>
      <p>
        If, for any reason, You are not completely satisfied with a purchase We
        invite You to review our policy on refunds and returns.
      </p>
      <p>
        The following terms are applicable for any products that You purchased
        with Us.
      </p>
      <h2 style={styles.h2}>Interpretation and Definitions</h2>
      <h3 style={styles.h3}>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h3 style={styles.h3}>Definitions</h3>
      <p>For the purposes of this Return and Refund Policy:</p>
      <ul>
        <li>
          <p>
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to LOGSPLUG.
          </p>
        </li>
        <li>
          <p>
            <strong>Goods</strong> refer to the items offered for sale on the
            Service.
          </p>
        </li>
        <li>
          <p>
            <strong>Orders</strong> mean a request by You to purchase Goods from
            Us.
          </p>
        </li>
        <li>
          <p>
            <strong>Service</strong> refers to the Website.
          </p>
        </li>
        <li>
          <p>
            <strong>Website</strong> refers to LOGSPLUG, accessible from{" "}
            <a
              href="https://www.logsplug.com"
              rel="external nofollow noopener"
              target="_blank"
            >
              https://www.logsplug.com
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </p>
        </li>
      </ul>
      <h2 style={styles.h2}>Refund Eligibility</h2>
      <h3 style={styles.h3}>
        To be eligible for a refund, please ensure that:
      </h3>
      <ol>
        <li>
          You notify us of your request for a refund within 7 days from the date
          of purchase.
        </li>
        <li>
          The item must be unused and in the same condition that you received
          it.
        </li>
        <li>The item must be in the original form.</li>
        <li>The item needs to have the receipt or proof of purchase.</li>
      </ol>

      <h2 style={styles.h2}>Refund Process</h2>
      <p>
        Once we receive your request for a refund and verify that your item
        meets the eligibility criteria, we will process your refund promptly.
        The refund will be issued to the original payment method used during the
        purchase.
      </p>

      <h2 style={styles.h2}>Non-Refundable Items</h2>
      <h2 style={styles.h2}>Refund Eligibility</h2>
      <h3 style={styles.h3}>
        Please note that certain items are not eligible for refunds, including
        but not limited to:
      </h3>
      <ol>
        <li>Downloadable digital products after the download has commenced.</li>
        <li>Services provided by third-party vendors on our platform.</li>
      </ol>

      <h2 style={styles.h2}>Defective Item</h2>
      <p>
        If you receive a defective item, please contact us
        immediately to arrange for a replacement or refund. We may require evidence of the defective to facilitate the process.
      </p>

      <ul>
        <li>
          <p>By email: logsplug@gmail.com</p>
        </li>
        <li>
          <p>By phone number: +2349063921346</p>
        </li>
      </ul>
      <p>
        We will reimburse You no later than 14 days from the day on which We
        receive the returned items. We will use the same means of payment as You
        used for the Order, and You will not incur any fees for such
        reimbursement.
      </p>

      <h3 style={styles.h3}>Contact Us</h3>
      <p>
        If you have any questions about our Returns and Refunds Policy, please
        contact us:
      </p>
      <ul>
        <li>
          <p>By email: logsplug@gmail.com</p>
        </li>
        <li>
          <p>By phone number: +2349063921346</p>
        </li>
      </ul>
    </div>
  );
};
