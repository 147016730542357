import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider } from "@mui/material";
import { format } from "date-fns";

export default function FaqAccordion({ item, index }) {
  return (
    <div className="w-full my-1">
      <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={index}
          id={index}
        >
          <Typography>{item?.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{item?.body}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export function NotificationsAccordion({ item, index }) {
  return (
    <div className="w-full my-1">
      <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={index}
          id={index}
        >
          <Typography>{item?.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p
              dangerouslySetInnerHTML={{
                __html: item?.message
              }}
            ></p>
            <sub className="flex justify-end py-1">
              {format(new Date(item?.createdAt), "yyyy-MM-dd HH:mm:ss")}
            </sub>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
