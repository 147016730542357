// AppRouter.js
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RequireAuth } from "./context/RequireAuth";
import Spinner from "components/Spinner";
import Notifications from "pages/notifications";
import { PrivacyPolicy } from "pages/landingpage/privacy-policy";
import { TermsOfService } from "pages/landingpage/terms-of-service";
import { RefundPolicy } from "pages/landingpage/refund-policy";

const Index = lazy(() => import("./pages/landingpage"));
const Layout = lazy(() => import("./pages/layout/Layout"));
const SignIn = lazy(() => import("./pages/auth/SignIn"));
const SignUp = lazy(() => import("./pages/auth/SignUp"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const LogsList = lazy(() => import("./pages/e-commerce/LogsList"));
const SellLogs = lazy(() => import("./pages/e-commerce/SellLogs"));
const LogsDashboard = lazy(() => import("./pages/e-commerce/LogsDashboard"));
const Pocket = lazy(() => import("./pages/wallet/Pocket"));
const Settings = lazy(() => import("./pages/settings/Settings"));
const OrdersHistory = lazy(() => import("./pages/e-commerce/OrdersHistory"));
const ContactUs = lazy(() => import("components/ContactForm"));
const NoMatch = lazy(() => import("./pages/NoMatch"));

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route exact index element={<Index />} />
          <Route exact path="/login" element={<SignIn />} />
          <Route exact path="/register" element={<SignUp />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-of-service" element={<TermsOfService />} />
          <Route exact path="/refund-policy" element={<RefundPolicy />} />

          <Route exact path="" element={<Layout />}>
            <Route element={<RequireAuth />}>
              <Route exact path="contact" element={<ContactUs />} />

              <Route exact path="logs-dashboard" element={<LogsDashboard />} />

              <Route exact path="logs" element={<LogsList />} />
              <Route exact path="logs/wallet" element={<Pocket />} />

              <Route exact path="order-history" element={<OrdersHistory />} />
              <Route exact path="order-history/:s" element={<OrdersHistory />} />
              <Route exact path="sell-logs" element={<SellLogs />} />
              <Route exact path="wallet" element={<Pocket />} />
              <Route exact path="notifications" element={<Notifications />} />
              <Route exact path="wallet/settings" element={<Settings />} />
              <Route exact path="settings" element={<Settings />} />
            </Route>
            <Route exact path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
