import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { setupListeners } from "@reduxjs/toolkit/query";
import { logsPlugApi } from "../services/AuthApi";
import { slice } from "../features/AuthSlice";
import persistStore from "redux-persist/es/persistStore";

const persistConfig = {
  // timeout: 2000, //Set the timeout function to 2 seconds
  key: "root",
  storage,
  blacklist: ["LogsPlugApi"],
  // stateReconciler: hardSet
}; 

const reducer = combineReducers({
  [logsPlugApi.reducerPath]: logsPlugApi.reducer,
  [slice.name]: slice.reducer,
});
 
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logsPlugApi.middleware),
    devTools: true,
});

export let persistor = persistStore(store);

export const RootState = store.getState;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
