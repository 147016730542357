import React, { useEffect, useRef } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuthUser from "hooks/useAuthUser";
import { toast } from "react-toastify";

export const RequireAuth = () => {
  const { user } = useAuthUser();
  const location = useLocation();
  const toastShown = useRef(false);

  useEffect(() => {
    if (!user && !toastShown.current) {
      toast("Only authenticated users can navigate");
      toastShown.current = true;
    }
  }, [user]);

  if (!user) {
    return <Navigate to="/" state={{ path: location.pathname }} />;
  }

  return <Outlet />;
};
