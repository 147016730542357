import React from "react";

const styles = {
  h1: { fontSize: "20px", fontWeight: "bold", color: "white" },
  h2: { fontSize: "16px", fontWeight: "bold", color: "white" },
  h3: { fontSize: "14px", fontWeight: "bold", color: "white" },
  color: "#c0c0c0"
};

export const TermsOfService = () => {
  return (
    <div style={styles} className="p-10 text-justify space-y-3">
      <h1 style={styles.h1}>Terms and Conditions</h1>
      <p>Last updated: June 08, 2024</p>
      <p>
        Please read these terms and conditions carefully before using Our
        Service.
      </p>

      <h2 style={styles.h2}>Interpretation and Definitions</h2>
      <h3 style={styles.h3}>Interpretation</h3>
      <p>
        The following terminology applies to these Terms and Conditions, Privacy
        Statement, and Disclaimer Notice and any or all Agreements: "Client",
        "You" and "Your" refers to you, the person accessing this website and
        accepting the Company's terms and conditions. "The Company",
        "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
        "Parties", or "Us", refers to both the Client and ourselves, or either
        the Client or ourselves. The words of which the initial letter is
        capitalized have meanings defined under the following conditions. The
        following definitions shall have the same meaning regardless of whether
        they appear in singular or in plural.
      </p>
      <h3 style={styles.h3}>Definitions</h3>
      <p>For the purposes of this Terms and Conditions:</p>
      <ul>
        <li>
          <p>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </p>
        </li>
        <li>
          <p>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            &quot;control&quot; means ownership of 50% or more of the shares,
            equity interest or other securities entitled to vote for election of
            directors or other managing authority.
          </p>
        </li>
        <li>
          <p>
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to LOGSPLUG.
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies</strong> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
          </p>
        </li>
        <li>
          <p>
            <strong>Country</strong> refers to: Nigeria
          </p>
        </li>
        <li>
          <p>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </p>
        </li>
        <li>
          <p>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </p>
        </li>
        <li>
          <p>
            <strong>Service</strong> refers to the Website.
          </p>
        </li>
        <li>
          <p>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </p>
        </li>
        <li>
          <p>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </p>
        </li>
        <li>
          <p>
            <strong>Website</strong> refers to LOGSPLUG, accessible from{" "}
            <a
              href="https://www.logsplug.com"
              rel="external nofollow noopener"
              target="_blank"
            >
              https://www.logsplug.com
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </p>
        </li>
      </ul>

      <h2 style={styles.h2}>Acknowledgment</h2>
      <p>
        These are the Terms and Conditions governing the use of this Service and
        the agreement that operates between You and the Company. These Terms and
        Conditions set out the rights and obligations of all users regarding the
        use of the Service.
      </p>
      <p>
        Your access to and use of the Service is conditioned on Your acceptance
        of and compliance with these Terms and Conditions. These Terms and
        Conditions apply to all visitors, users and others who access or use the
        Service.
      </p>
      <p>
        By accessing or using the Service You agree to be bound by these Terms
        and Conditions. If You disagree with any part of these Terms and
        Conditions then You may not access the Service.
      </p>
      <p>
        You represent that you are over the age of 18. The Company does not
        permit those under 18 to use the Service.
      </p>
      <p>
        Your access to and use of the Service is also conditioned on Your
        acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes Our policies and procedures on the collection,
        use and disclosure of Your personal information when You use the
        Application or the Website and tells You about Your privacy rights and
        how the law protects You. Please read Our Privacy Policy carefully
        before using Our Service.
      </p>
      <h2 style={styles.h2}>Links to Other Websites</h2>
      <p>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by the Company.
      </p>
      <p>
        The Company has no control over, and assumes no responsibility for, the
        content, privacy policies, or practices of any third party web sites or
        services. You further acknowledge and agree that the Company shall not
        be responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services.
      </p>
      <p>
        We strongly advise You to read the terms and conditions and privacy
        policies of any third-party web sites or services that You visit.
      </p>
      <h2 style={styles.h2}>Account Creation</h2>
      <p>
        To access certain features of LogsPlug, you may be required to create an
        account. You must provide accurate and complete information when
        creating an account on the website. You are solely responsible for
        maintaining the confidentiality of your account credentials and for all
        activities that occur under your account. You agree to notify us
        immediately of any unauthorized use of your account or any other breach
        of security.
      </p>
      <h2 style={styles.h2}>Termination</h2>
      <p>
        We may terminate or suspend Your access immediately, without prior
        notice or liability, for any reason whatsoever, including without
        limitation if You breach these Terms and Conditions.
      </p>
      <p>
        Upon termination, Your right to use the Service will cease immediately.
      </p>
      <h2 style={styles.h2}>User Content</h2>
      <p>
        By uploading, posting, or otherwise transmitting any content on
        LogsPlug, you grant us a non-exclusive, worldwide, royalty-free,
        sublicensable, and transferable license to use, reproduce, distribute,
        prepare derivative works of, and display the content in connection with
        the website and our business.
      </p>
      <h2 style={styles.h2}>Purchases</h2>
      <p>
        If you wish to purchase any product or service made available through
        LogsPlug, you may be asked to supply certain information relevant to
        your purchase including, without limitation, your name, address, email
        address, and payment information.
      </p>
      <h2 style={styles.h2}>Limitation of Liability</h2>
      <p>
        In no event shall LogsPlug, nor any of its officers, directors, and
        employees, be liable to you for anything arising out of or in any way
        connected with your use of this website, whether such liability is under
        contract, tort or otherwise, and LogsPlug, including its officers,
        directors, and employees shall not be liable for any indirect,
        consequential or special liability arising out of or in any way related
        to your use of this website.
      </p>

      <h2 style={styles.h2}>
        &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer
      </h2>
      <p>
        The Service is provided to You &quot;AS IS&quot; and &quot;AS
        AVAILABLE&quot; and with all faults and defects without warranty of any
        kind. To the maximum extent permitted under applicable law, the Company,
        on its own behalf and on behalf of its Affiliates and its and their
        respective licensors and service providers, expressly disclaims all
        warranties, whether express, implied, statutory or otherwise, with
        respect to the Service, including all implied warranties of
        merchantability, fitness for a particular purpose, title and
        non-infringement, and warranties that may arise out of course of
        dealing, course of performance, usage or trade practice. Without
        limitation to the foregoing, the Company provides no warranty or
        undertaking, and makes no representation of any kind that the Service
        will meet Your requirements, achieve any intended results, be compatible
        or work with any other software, applications, systems or services,
        operate without interruption, meet any performance or reliability
        standards or be error free or that any errors or defects can or will be
        corrected.
      </p>
      <p>
        Without limiting the foregoing, neither the Company nor any of the
        company's provider makes any representation or warranty of any kind,
        express or implied: (i) as to the operation or availability of the
        Service, or the information, content, and materials or products included
        thereon; (ii) that the Service will be uninterrupted or error-free;
        (iii) as to the accuracy, reliability, or currency of any information or
        content provided through the Service; or (iv) that the Service, its
        servers, the content, or e-mails sent from or on behalf of the Company
        are free of viruses, scripts, trojan horses, worms, malware, timebombs
        or other harmful components.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of certain types of
        warranties or limitations on applicable statutory rights of a consumer,
        so some or all of the above exclusions and limitations may not apply to
        You. But in such a case the exclusions and limitations set forth in this
        section shall be applied to the greatest extent enforceable under
        applicable law.
      </p>
      <h2 style={styles.h2}>Governing Law</h2>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of Nigeria, and you irrevocably submit to the exclusive
        jurisdiction of the courts in that State or location.
      </p>
      <h2 style={styles.h2}>Disputes Resolution</h2>
      <p>
        If You have any concern or dispute about the Service, You agree to first
        try to resolve the dispute informally by contacting the Company.
      </p>
      <h2 style={styles.h2}>Severability and Waiver</h2>
      <h3 style={styles.h3}>Severability</h3>
      <p>
        If any provision of these Terms is held to be unenforceable or invalid,
        such provision will be changed and interpreted to accomplish the
        objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </p>
      <h3 style={styles.h3}>Waiver</h3>
      <p>
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under these Terms shall not affect a
        party's ability to exercise such right or require such performance at
        any time thereafter nor shall the waiver of a breach constitute a waiver
        of any subsequent breach.
      </p>

      <h2 style={styles.h2}>Translation Interpretation</h2>
      <p>
        These Terms and Conditions may have been translated if We have made them
        available to You on our Service. You agree that the original English
        text shall prevail in the case of a dispute.
      </p>
      <h2 style={styles.h2}>Changes to These Terms and Conditions</h2>
      <p>
        We reserve the right, at Our sole discretion, to modify or replace these
        Terms at any time. If a revision is material We will make reasonable
        efforts to provide at least 30 days' notice prior to any new terms
        taking effect. What constitutes a material change will be determined at
        Our sole discretion.
      </p>
      <p>
        By continuing to access or use Our Service after those revisions become
        effective, You agree to be bound by the revised terms. If You do not
        agree to the new terms, in whole or in part, please stop using the
        website and the Service.
      </p>

      <h2 style={styles.h2}>Contact Us</h2>
      <p>
        If you have any questions about these Terms and Conditions, You can
        contact us:
      </p>

      <ul>
        <li>
          <p>By email: logsplug@gmail.com</p>
        </li>
        <li>
          <p>By phone number: +2349063921346</p>
        </li>
      </ul>
    </div>
  );
};
